import { Component, OnDestroy, OnInit } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { User } from "./shared/model/user";

@Component({
    imports: [RouterOutlet],
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit, OnDestroy { user: User | undefined = undefined;

  constructor() {}
  title = "TopGun";

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
