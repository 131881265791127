import { Component, inject, Input } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AuthService } from '../authentication.service';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-create-new-password',
    imports: [CommonModule, ReactiveFormsModule, RouterModule],
    templateUrl: './create-new-password.component.html',
    styleUrl: './create-new-password.component.css'
})
export class CreateNewPasswordComponent {

    @Input() token: string;
    @Input() email: string;
  
    route = inject(ActivatedRoute);
    authService = inject(AuthService);
    matchingPassword: boolean = false;
    showError: boolean;
    resetSuccess: boolean
    requestError: boolean;
    minLengthValid: boolean = false;
    uppercaseValid: boolean = false;
    lowercaseValid: boolean = false;
    specialCharValid: boolean = false;
    oneDigitValid: boolean = false;
  
    btnDisabled: boolean = true;
  
    resetPasswordForm = new FormGroup({
      password: new FormControl(''),
      confirmPassword: new FormControl(''),
    });
  
  
      ngOnInit() {
      // Listen for changes in confirmPassword field
      this.resetPasswordForm.get('password')?.valueChanges.subscribe(() => this.checkPasswordRules());
      this.resetPasswordForm.get('confirmPassword')?.valueChanges.subscribe(() => this.checkPassword());
    }
  
    checkPasswordRules() {
      const password = this.resetPasswordForm.get('password')?.value;
      if (password) {
        this.minLengthValid = password.length >= 8;
        this.uppercaseValid = /[A-Z]/.test(password);
        this.lowercaseValid = /[a-z]/.test(password);
        this.specialCharValid = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        this.oneDigitValid = /\d/.test(password);
      }
  
      this.updateBtnState();
  
    }
  
    checkPassword() {
      const password = this.resetPasswordForm.get('password')?.value;
      const confirmPassword = this.resetPasswordForm.get('confirmPassword')?.value;
  
      this.matchingPassword = password === confirmPassword;
  
      this.showError = !this.matchingPassword && confirmPassword !== '';
  
      this.updateBtnState();
    }
  
    updateBtnState() {
      this.btnDisabled = !(this.minLengthValid &&
        this.uppercaseValid &&
        this.lowercaseValid &&
        this.specialCharValid &&
        this.oneDigitValid &&
        this.matchingPassword);
    }
  
    onSubmit() {
      this.btnDisabled = true;
      const password = this.resetPasswordForm.get('password')?.value;
      const confirmPassword = this.resetPasswordForm.get('confirmPassword')?.value;
  
      if (
        this.matchingPassword &&
        password &&
        confirmPassword &&
        this.minLengthValid &&
        this.uppercaseValid &&
        this.lowercaseValid &&
        this.specialCharValid &&
        this.oneDigitValid
      ) {
        this.requestError = false;
        this.authService.sendResetPassword(this.email, password, this.token).subscribe({
            next: (res) => {
            this.resetSuccess = true;
            this.btnDisabled = false;
          },
          error: (error) => {
              this.requestError = true;
              this.btnDisabled = false;
          }
        });
      } 
    }
  
  }
  