import {Component, EventEmitter, inject, OnInit, Output, ViewChild,} from "@angular/core";
import {Router} from "@angular/router";
import {FormsModule, NgForm, NgModel} from "@angular/forms";
import {UserService} from "../../../features/user/user.service";
import {DomSanitizer} from "@angular/platform-browser";
import {ValidateEmailServiceService} from "src/app/shared/service/validate-email-service.service";
import {takeUntil} from "rxjs/operators";
import {CommonModule} from "@angular/common";
import {SnackbarComponent} from "src/app/shared/components/snackbar/snackbar.component";
import {SnackbarData} from "src/app/shared/types/SnackbarData";
import {Register} from "src/app/shared/model/register";
import {User} from "src/app/shared/model/user";
import {Gender, genderDisplay} from "src/app/shared/model/gender";
import {Store} from "@ngrx/store";
import {lastValueFrom, Observable, Subject} from "rxjs";

@Component({
    imports: [CommonModule, FormsModule, SnackbarComponent],
    selector: "app-register",
    templateUrl: "./register.component.html",
    styleUrls: ["./register.component.css"]
})
export class RegisterComponent implements OnInit {
  user$: Observable<User>;
  store = inject(Store)
  genders = Gender; // This is the Gender enum
  genderDisplay = genderDisplay;
  visible = false;
  message: string;
  hasAction: boolean = false;
  data: SnackbarData;
  cancelUserDataFetching$: Subject<void> = new Subject();
  @Output() loadingStateChanged: EventEmitter<boolean> = new EventEmitter();
  public loading: boolean = false;
  @Output() UserRegistered: EventEmitter<any> = new EventEmitter();
  oldPassword: string | null = null;
  currentUser: User | null = null;
  canceled: boolean = false;
  showSpinner: boolean = false;
  public showRegisterAnimal: boolean = false;
  public profileImg: any;
  public profileImgUrl: any = null;
  public page: number = 1;
  public registerData: Register;
  public password2 = "";
  public showErrorMessage: boolean = true;
  emailExists: boolean = false;
  day: number;
  month: number;
  year: number;
  @ViewChild("registerForm", {static: false}) registerForm: NgForm;
  @ViewChild('socialSecurityNumberControl', {static: false}) socialSecurityNumberControl: NgModel;

  constructor(
    private router: Router,
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private validateEmail: ValidateEmailServiceService,
  ) {
  }

  async ngOnInit() {
    this.registerData = new Register();
    if (this.router.url == "/update") {
      this.user$ = this.store.select(store => store.user.currentUser);
      const user = await lastValueFrom(this.user$);
      const {year, month, day} = this.getBirthDay(
        user.socialSecurityNumber
      );
      this.year = Number(year);
      this.month = Number(month);
      this.day = Number(day);
      this.registerData.gender = user.gender;
      this.registerData.firstName = user.firstName;
      this.registerData.lastName = user.lastName;
      this.registerData.socialSecurityNumber = user.socialSecurityNumber;
      this.registerData.email = user.email;
      this.registerData.password = "";
      this.registerData.address.city = user.address.city;
      this.registerData.address.street = user.address.street;
      this.registerData.address.country = user.address.country;
      this.registerData.address.zipCode = user.address.zipCode;
      this.registerData.agreeMarketing = user.agreeMarketing;
      this.registerData.subscribeToEmailNotification =
        user.subscribeToEmailNotification;
    } else {
      this.registerData.firstName = "";
      this.registerData.lastName = "";

      this.registerData.socialSecurityNumber = "";
      this.registerData.email = "";
      this.registerData.password = "";
      this.registerData.address.city = null;
      this.registerData.address.street = null;
      this.registerData.address.country = null;
      this.registerData.address.zipCode = null;
      this.registerData.gender = Gender.EjValt;
      this.registerData.agreeMarketing = true;
      this.registerData.subscribeToEmailNotification = true;
    }
  }

  showSnackbar(type: string, message: string, action: string) {
    this.visible = true;
    this.hasAction = false;
    if (action && action.length > 0) this.hasAction = true;
    this.data = {
      message,
      action,
      type,
    };
  }

  actionClick() {

  }

  public registerNewUser(): void {
    this.userService.addUser(this.registerData).subscribe((res) => {
      this.UserRegistered.emit({
        password: this.registerData.password,
        email: this.registerData.email,
      });
    });

  }

  navigate(): void {
    this.router.navigate(['login']);
  }

  public onGenderChange(value: string): void {
    this.registerData.gender = +value; // Convert string to number
  }

  checkSame(password2: string) {

    try {
      const secondPassword = password2;
      const firstPassword = this.registerData.password;

      if (secondPassword === firstPassword) {

        this.showErrorMessage = true;
        this.registerForm.controls.password2.markAsDirty();
        this.registerForm.form.controls.password2.setErrors(null);
      } else {

        this.showErrorMessage = false;
        this.registerForm.form.controls.password2.setErrors({incorrect: true});
        this.registerForm.controls.password2.markAsPristine();
      }
    } catch {

    }
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  addMedia(event: any, mediaType: string) {
    const files: File[] = event.target.files;
    this.profileImg = files[0];
    const mediaUrl = URL.createObjectURL(files[0]);
    const sanitizedMedia = this.sanitize(mediaUrl);
    this.profileImgUrl = sanitizedMedia;
  }

  showModal() {
    this.showRegisterAnimal = !this.showRegisterAnimal
  }

  closeModal(event: string) {
    this.showRegisterAnimal = false;
  }

  checkEmail(event: any) {

    const regex = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    const input = this.registerData.email;
    if (this.currentUser && this.currentUser.email == this.registerData.email)
      return;
    const result = regex.test(input);

    if (result) {
      this.validateEmail
        .validateEmail(this.registerData.email)
        .subscribe((res) => {

          this.emailExists = !res.valid;
        });
    }
  }

  getBirthDay(pn: string) {

    const bd = pn.slice(0, 8);
    const year = bd.slice(0, 4);
    const month = bd.slice(4, 6);
    const day = bd.slice(6, 8);
    return {year, month, day};
  }

  cancelDataFetching(e: string) {
    this.cancelUserDataFetching$.next();
    this.showSpinner = false;
  }

  getUserDataIfValid(socialSecurityNumberControl: NgModel) {
    if (socialSecurityNumberControl.valid) {
      const anotherElement = document.createElement('input');
      document.body.appendChild(anotherElement);
      anotherElement.focus();
      document.body.removeChild(anotherElement);
//      this.getUserData();
    }
  }

  getUserData(): void {
    let num = this.registerData.socialSecurityNumber;
    if (this.registerData.socialSecurityNumber.includes("-")) {
      num = this.registerData.socialSecurityNumber.replace("-", "");
    }
    if (num.length == 12) {
      this.loading = true;
      this.loadingStateChanged.emit(this.loading);
      this.showSpinner = true;
      this.registerData.socialSecurityNumber = num;
      this.userService
        .getUserData(num)
        .pipe(takeUntil(this.cancelUserDataFetching$))
        .subscribe(
          (userData) => {

            this.loadingStateChanged.emit(false);
            const {year, month, day} = this.getBirthDay(
              this.registerData.socialSecurityNumber
            );
            this.registerData.firstName =
              userData?.firstName || this.registerData.firstName;
            this.registerData.lastName =
              userData?.lastName || this.registerData.lastName;
            this.registerData.address.city =
              userData?.address?.city || this.registerData.address.city;
            this.registerData.address.street =
              userData?.address?.street || this.registerData.address.street;
            this.registerData.address.zipCode =
              userData?.address?.zipCode || this.registerData.address.zipCode;
            this.registerData.gender =
              userData?.gender || this.registerData.gender;
            this.year = Number(year);
            this.month = Number(month);
            this.day = Number(day);
            this.showSpinner = false;
          },
          (err) => {

            this.loadingStateChanged.emit(this.loading);
            this.showSpinner = false;
          }
        );
    }
  }

  public onAgreeMarketingChanged(value: boolean) {
    this.registerData.agreeMarketing = value;
  }

  public onSubscribeToEmailChanged(value: boolean) {
    this.registerData.subscribeToEmailNotification = value;
  }
}
